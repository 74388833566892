"use client";

import Image from "next/image";
import { useIntl } from "react-intl";
import { cx } from "@/helpers/cx";

const testimonialList = [
  {
    author: {
      avatarUrl: "/barno-bensahel.jpg",
      callSign: "Professors of Practice at JHU SAIS",
      displayName: "Dave Barno and Nora Bensahel",
    },
    body: "The Defense Futures Simulator has been an indispensable tool for our SAIS graduate class that requires teams to construct a US Defense Strategy with a five year detailed budget plan. Our student teams use the DFS to gain an in-depth understanding of the difficult decisions and trade offs for different budget scenarios and how they would shape the U.S. military for its worldwide commitments. We have been amazed at how much our students have learned using the DFS and we plan to continue to make this project the centerpiece of our course!",
  },
  {
    author: {
      avatarUrl: "/mcCusker.jpg",
      callSign: "Former Deputy and Acting DoD Comptroller and current AEI Senior Fellow",
      displayName: "Elaine McCusker",
    },
    body: "The AEI Defense Futures Simulator is a uniquely useful tool for connecting strategy to budget as it credibly generates if/then scenarios to improve understanding of force structure, capability and other programmatic decisions and trade-offs. For example, DFS was the engine behind the analysis in my recent work to explain the defense budget implications of a Russian victory over Ukraine and therefore why assisting Ukraine is in the best financial interest of the United States.",
  },
];

export const TestimonialSection = () => {
  const intl = useIntl();
  const mediumOffsetList: number[] = [];
  const largeOffsetList: number[] = [];
  const isLargeList = testimonialList.length > 2;

  if (testimonialList.length % 2 > 0) {
    mediumOffsetList.push(Math.floor(testimonialList.length / 2));
  }

  if (testimonialList.length % 3 > 0 && isLargeList) {
    largeOffsetList.push(
      Math.floor(testimonialList.length / 3),
      Math.floor(testimonialList.length / 3) + Math.ceil(testimonialList.length / 3),
    );
  }

  return (
    <section className="bg-white py-24 dark:bg-black sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <header className="mx-auto max-w-xl text-center">
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-neutral-12 sm:text-4xl">
            {intl.formatMessage({
              defaultMessage: "We’ve worked with lots of amazing people",
              id: "x1NLE2",
            })}
          </h1>
        </header>
        <div className="mx-auto mt-16 max-w-7xl sm:mt-20">
          <div className="mx-auto flow-root max-w-2xl lg:mx-0 lg:max-w-none">
            <ul
              className={cx(
                isLargeList
                  ? "-mt-8 sm:-mx-4 sm:columns-2 sm:text-0 lg:columns-3"
                  : "flex flex-col gap-2 sm:flex-row sm:justify-center",
              )}
            >
              {testimonialList.map((testimonial, index) => (
                <li
                  className={`${mediumOffsetList.includes(index) ? "md:ms-1/2" : "md:ms-0"} ${largeOffsetList.includes(index) ? "lg:ms-1/2" : "lg:ms-0"}`}
                  key={testimonial.author.callSign}
                >
                  <figure className="rounded-2xl bg-white-a-12 p-8 text-sm leading-6 shadow dark:bg-white-a-1">
                    <blockquote className="-indent-quote text-neutral-a-12">
                      <p className="before:content-open-quote after:content-close-quote">
                        {testimonial.body}
                      </p>
                    </blockquote>
                    <figcaption className="mt-6 flex items-center gap-x-4">
                      {testimonial.author.avatarUrl !== "" && (
                        <Image
                          alt=""
                          className="size-10 rounded-full bg-neutral-3"
                          height={40}
                          src={testimonial.author.avatarUrl}
                          width={49}
                        />
                      )}
                      <div>
                        <div className="font-semibold leading-tight text-neutral-a-12">
                          {testimonial.author.displayName}
                        </div>
                        <div className="leading-tight text-neutral-a-11">
                          {intl.formatMessage(
                            {
                              defaultMessage: "{callSign}",
                              id: "Hf3w0k",
                            },
                            {
                              callSign: testimonial.author.callSign,
                            },
                          )}
                        </div>
                      </div>
                    </figcaption>
                  </figure>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
